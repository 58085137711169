import { Form } from "react-bootstrap";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

const ToolList = () => {
  const [selectedTool, setSelectedTool] = useState<number>();

  const location = useLocation();

  const list = [
    {
      name: "Base64",
      url: "base64",
    },
    {
      name: "Url Encode",
      url: "urlencode",
    },
    {
      name: "Google Translate Web",
      url: "google-translate",
    },
  ];

  const changeHandler = (i: number) => {
    window.location.href = `${process.env.GATSBY_HOST}/tools/${list[i].url}/`;
  };

  useEffect(() => {
    const i = list.findIndex((el) => {
      return `/tools/${el.url}/` === location.pathname;
    });

    setSelectedTool(i);
  }, []);

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Select
          onChange={(e) => {
            changeHandler(parseInt(e.currentTarget.value));
          }}
          value={selectedTool}
        >
          <option disabled>Tools</option>
          {list.map((e, i) => (
            <option key={i} value={i}>{e.name}</option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default ToolList;
