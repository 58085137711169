import * as React from "react";
import { useState } from "react";
import PageHelmet from "../../components/common/helmet";
import { Container, FloatingLabel, Form } from "react-bootstrap";
import ToolList from "../../components/tools/toolList";

const GoogleTranslatePage: React.FC = () => {
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");

  const handleBlur = (input: string) => {
    if (!input) return;
    const inputUrl = new URL(input);

    const gtQuery =
      "_x_tr_sl=zh-CN&_x_tr_tl=en&_x_tr_hl=en-US&_x_tr_pto=ajax,elem";

    const host = inputUrl.hostname.replace(/\./g, "-") + ".translate.goog";
    const path = inputUrl.pathname;
    const query =
      inputUrl.search === "" ? "?" + gtQuery : inputUrl.search + gtQuery;

    const newUrl = `https://${host}${path}${query}`;
    setUrl(newUrl);
  };

  return (
    <>
      <PageHelmet title="Google Translate Web" />
      <Container className="pt-3">
        <ToolList />

        <FloatingLabel controlId="plainText" label="Plain Text">
          <Form.Control
            as="textarea"
            placeholder="Plain text"
            style={{ height: "250px" }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onBlur={(e) => {
              handleBlur(e.target.value);
            }}
          />
        </FloatingLabel>

        <div className="mt-2 d-grid">
          <a href={url} target="_blank" className="btn btn-primary">
            Open
          </a>
        </div>
      </Container>
    </>
  );
};

export default GoogleTranslatePage;
